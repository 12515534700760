<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="护照号码" prop="passportNumber" v-if="form.passportNumber">
        <a-input v-model="form.passportNumber" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="logo" prop="logo" v-if="form.logo">
        <img  v-viewer  style="width: 48px;height: 48px;margin-top: 5px;margin-right: 5px;"
              :src="form.logo" preview="头像"/>
      </a-form-model-item>
      <a-form-model-item label="名字" prop="userName" v-if="form.userName">
        <a-input v-model="form.userName" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="出生日期" prop="birthday" v-if="form.birthday">
        <a-date-picker disabled style="width: 100%" v-model="form.birthday" format="YYYY-MM-DD" />
      </a-form-model-item>
      <a-form-model-item label="公司名称" prop="companyName" v-if="form.companyName">
        <a-input v-model="form.companyName" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="工作职位" prop="jobPosition" v-if="form.jobPosition">
        <a-input v-model="form.jobPosition" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>





      <a-form-model-item label="入职时间" prop="hiredate" v-if="form.hiredate">
        <a-date-picker disabled style="width: 100%" v-model="form.hiredate" format="YYYY-MM-DD" />
      </a-form-model-item>
      <a-form-model-item label="税前工资" prop="grossWage" v-if="form.grossWage">
        <a-input v-model="form.grossWage" placeholder="请输入税前工资" />{{form.unit}}
      </a-form-model-item>
<!--      <a-form-model-item label="工资单位 元，万" prop="unit" v-if="form.grossWage">-->
<!--        <a-input v-model="form.unit" placeholder="请输入工资单位 元，万" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="公司电话" prop="companyMobile" v-if="form.companyMobile">
        <a-input v-model="form.companyMobile" placeholder="请输入公司" />
      </a-form-model-item>


      <a-form-model-item label="公司地址" prop="companyAddress" v-if="form.companyAddress">
        <a-input v-model="form.companyAddress" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="商户名称" prop="storeName" v-if="form.storeName">
        <a-input v-model="form.storeName" placeholder="请输入商户电话" />
      </a-form-model-item>
      <a-form-model-item label="商户电话" prop="storeMobile" v-if="form.storeMobile">
        <a-input v-model="form.storeMobile" placeholder="请输入商户电话" />
      </a-form-model-item>


      <a-form-model-item label="商户地址" prop="storeAddress" v-if="form.storeAddress">
        <a-input v-model="form.storeAddress" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="成立年份" prop="establishYear" v-if="form.establishYear">
        <a-input v-model="form.establishYear" placeholder="请输入成立年份" />
      </a-form-model-item>
      <a-form-model-item label="学校" prop="schoolName" v-if="form.schoolName">
        <a-input v-model="form.schoolName" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>



      <a-form-model-item label="专业" prop="profession" v-if="form.profession">
        <a-input v-model="form.profession" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="出发时间" prop="departureTime"  v-if="form.departureTime">
        <a-date-picker disabled style="width: 100%" v-model="form.departureTime" format="YYYY-MM-DD" />
      </a-form-model-item>
      <a-form-model-item label="目的地 多个用英文逗号隔开" prop="destination" v-if="form.destination">
        <a-input v-model="form.destination" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>



      <a-form-model-item label="出发城市" prop="departureCity" v-if="form.departureCity">
        <a-input v-model="form.departureCity" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="出发国家" prop="departureCountry" v-if="form.departureCountry">
        <a-input v-model="form.departureCountry" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="返程国家" prop="returnCountry" >
        <a-input v-model="form.returnCountry" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>

      
      <a-form-model-item label="返程城市" prop="returnCity" v-if="form.returnCity">
        <a-input v-model="form.returnCity" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="返程时间" prop="returnTime" v-if="form.returnTime">
        <a-date-picker disabled style="width: 100%" v-model="form.returnTime" format="YYYY-MM-DD" />
      </a-form-model-item>
      <a-form-model-item label="出行目的" prop="tripPurpose" v-if="form.tripPurpose">
        <a-input v-model="form.tripPurpose" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="财务证明" prop="financialCertificate" v-if="form.financialCertificate">
        <a-input v-model="form.financialCertificate" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="回国保证" prop="returnGuarantee"  v-if="form.returnGuarantee">
        <a-input v-model="form.returnGuarantee" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
<!--      <a-form-model-item label="cover letter下载链接" prop="coverLetterUrl" >-->
<!--        <a-input v-model="form.coverLetterUrl" placeholder="请输入cover letter下载链接" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="行程单下载链接" prop="itinerary" >-->
<!--        <a-input v-model="form.itinerary" placeholder="请输入行程单下载链接" />-->
<!--      </a-form-model-item>-->
       <div class="bottom-control">
        <a-space>
<!--          <a-button type="primary" :loading="submitLoading" @click="submitForm">-->
<!--            保存-->
<!--          </a-button>-->
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getApplyAttr, addApplyAttr, updateApplyAttr } from '@/api/user/applyAttr'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        profileId: null,
        logo: null,
        userName: null,
        birthday: null,
        passportNumber: null,
        companyName: null,
        jobPosition: null,
        schoolName: null,
        hiredate: null,
        grossWage: null,
        unit: null,
        companyMobile: null,
        storeMobile: null,
        storeName:null,
        companyAddress: null,
        storeAddress:null,
        establishYear: null,
        profession: null,
        departureTime: null,
        destination: null,
        departureCity: null,
        departureCountry: null,
        returnCountry: null,
        returnCity: null,
        returnTime: null,
        tripPurpose: null,
        financialCertificate: null,
        returnGuarantee: null,
        coverLetterUrl: null,
        itinerary: null,
        createTime: null,
        remark: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        // profileId: [
        //   { required: true, message: '签证申请资料ID不能为空', trigger: 'blur' }
        // ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        profileId: null,
        logo: null,
        userName: null,
        birthday: null,
        passportNumber: null,
        companyName: null,
        jobPosition: null,
        schoolName: null,
        hiredate: null,
        grossWage: null,
        unit: null,
        companyMobile: null,
        storeMobile: null,
        storeName:null,
        companyAddress: null,
        storeAddress:null,
        establishYear: null,
        profession: null,
        departureTime: null,
        destination: null,
        departureCity: null,
        departureCountry: null,
        returnCountry: null,
        returnCity: null,
        returnTime: null,
        tripPurpose: null,
        financialCertificate: null,
        returnGuarantee: null,
        coverLetterUrl: null,
        itinerary: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getApplyAttr({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateApplyAttr(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addApplyAttr(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
