import request from '@/utils/request'


// 查询工作证明、行程单列表
export function listApplyAttr(query) {
  return request({
    url: '/user/applyAttr/list',
    method: 'get',
    params: query
  })
}

// 查询工作证明、行程单分页
export function pageApplyAttr(query) {
  return request({
    url: '/user/applyAttr/page',
    method: 'get',
    params: query
  })
}

// 查询工作证明、行程单详细
export function getApplyAttr(data) {
  return request({
    url: '/user/applyAttr/detail',
    method: 'get',
    params: data
  })
}

// 新增工作证明、行程单
export function addApplyAttr(data) {
  return request({
    url: '/user/applyAttr/add',
    method: 'post',
    data: data
  })
}

// 修改工作证明、行程单
export function updateApplyAttr(data) {
  return request({
    url: '/user/applyAttr/edit',
    method: 'post',
    data: data
  })
}

// 删除工作证明、行程单
export function delApplyAttr(data) {
  return request({
    url: '/user/applyAttr/delete',
    method: 'post',
    data: data
  })
}
